import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import VellbyLogin from './vellby_components/LoginPage/LoginPage';
import WarmdataLogin from './warmdata_components/LoginPage/LoginPage';
import FlouweLogin from './flouwe_components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ChatProvider>
        <App />
      </ChatProvider>
    </VideoProvider>
  );
};

const WarmDataSwitch = () => (
  <Switch>
    <PrivateRoute exact path="/">
      <VideoApp />
    </PrivateRoute>
    <PrivateRoute path="/room/:URLRoomName">
      <VideoApp />
    </PrivateRoute>
    <Route path="/login">
      <WarmdataLogin />
    </Route>
    <Redirect to="/" />
  </Switch>
);

const VellbySwitch = () => (
  <Switch>
    <PrivateRoute exact path="/">
      <VideoApp />
    </PrivateRoute>
    <PrivateRoute path="/room/:URLRoomName">
      <VideoApp />
    </PrivateRoute>
    <Route path="/login">
      <VellbyLogin />
    </Route>
    <Redirect to="/" />
  </Switch>
);

const FlouweSwitch = () => (
  <Switch>
    <PrivateRoute exact path="/">
      <VideoApp />
    </PrivateRoute>
    <PrivateRoute path="/room/:URLRoomName">
      <VideoApp />
    </PrivateRoute>
    <Route path="/login">
      <FlouweLogin />
    </Route>
    <Redirect to="/" />
  </Switch>
);

const RouteSwitch = () => {
  const appName = process.env.REACT_APP_HOST_APP;
  console.log('APP NAME ', appName);
  switch (appName) {
    case 'vellby':
      return <VellbySwitch />;
    case 'warmdata':
      return <WarmDataSwitch />;
    case 'flouwe':
      return <FlouweSwitch />;
    default:
      return <VellbySwitch />;
  }
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <RouteSwitch />
        </AppStateProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>,
  document.getElementById('root')
);
